import {
  PieChart,
  Tooltip as PieTooltip,
  Cell,
  LineChart,
  BarChart,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
  Brush,
  ReferenceArea,
  ReferenceDot,
} from "recharts";
import { Box } from "@material-ui/core";
import GraficCard from "./formComponents/GraphicCard";
import CalculateIcon from "@mui/icons-material/Calculate";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import { useState } from "react";
import ColorMeterConsumGraph from "../meters/ColorMeterConsumGraph";
import { setConsumRange } from "../../redux/slices/meterSlice";
import { format } from "date-fns";
import { useEffect } from "react";
import { Typography } from "@mui/material";

const MeterConsumptionsGrafic = ({
  meterData,
  width,
  heatMeter,
  type,
  allocatorConsum = false,
}) => {
  const { t } = useTranslation();
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const smallScreen = screenWidth == 1280 && screenHeight == 720;
  const [aloccatorValue, setAllocatorValue] = useState(true);
  const [zoomState, setZoomState] = useState({});
  const consumptionsRangeElements = useSelector(
    (state) => state?.meterSlice?.consumptionsRangeElements
  );
  const dispatch = useDispatch();
  const data = meterData?.map((item) => ({
    name: t("date_val", { val: new Date(item?.date) }),
    value: item?.value,
    type: item?.type,
    consumptionType: item?.consumption_type,
  }));
  const allocatorValueToggle = () => [setAllocatorValue(!aloccatorValue)];
  const convertedGraphData = meterData
    ?.filter((item) => item?.consumption_type == "converted_value")
    ?.map((item) => ({
      ...item,
      value: item?.value?.toFixed(0),
    }));
  const unconvertedGraphData = meterData
    ?.filter((item) => item?.consumption_type == "unconverted_value")
    ?.map((item) => ({
      ...item,
      value: item?.value?.toFixed(0),
    }));
  const heatConumptionData = [];

  useEffect(() => {
    console.log("widthwidth", width);
  }, [width]);
  const res = meterData.reduce((acc, curr) => {
    const currentDate = new Date(curr.date);

    const formattedDate = currentDate.toISOString();

    if (
      currentDate.getTime() === currentDate.getTime() &&
      curr.consumption_type === "heat"
    ) {
      acc[formattedDate] = {
        ...acc[formattedDate],
        calories: curr.value,
        type: curr.type,
      };
    } else if (
      currentDate.getTime() === currentDate.getTime() &&
      curr.consumption_type === "cold"
    ) {
      acc[formattedDate] = {
        ...acc[formattedDate],
        frigories: curr.value,
        type: curr.type,
      };
    }
    return acc;
  }, {});
  const resultArray = Object.keys(res)?.map((date) => ({
    date,
    calories: res[date].calories / 1000 || 0,
    frigories: res[date].frigories / 1000 || 0,
    type: res[date].type,
  }));
  const [range, setRange] = useState({
    start: resultArray?.length > 10 ? resultArray?.length - 10 : 0,
    end: resultArray.length - 1,
  });
  const heatData = data?.map((item) => ({
    name: item?.name,
    calories: item?.consumptionType == "heat" ? item?.value : 0,
    frigories: item?.consumptionType == "cold" ? item?.value : 0,
    type: item?.type,
    consumptionType: item?.consumptionType,
  }));
  useEffect(() => {}, [unconvertedGraphData]);
  useEffect(() => {
    if (consumptionsRangeElements && type == "13" && resultArray?.length > 0) {
      setRange({
        end: resultArray.findIndex(
          (el) =>
            t("date_val", {
              val: new Date(el.date),
            }) === consumptionsRangeElements?.start?.date.date
        ),
        start: resultArray.findIndex(
          (el) =>
            t("date_val", {
              val: new Date(el.date),
            }) === consumptionsRangeElements?.end?.date.date
        ),
      });
    }
    if (
      consumptionsRangeElements &&
      type == "08" &&
      convertedGraphData?.length > 0
    ) {
      setRange({
        end: aloccatorValue
          ? convertedGraphData.findIndex(
              (el) =>
                t("date_val", {
                  val: new Date(el.date),
                }) === consumptionsRangeElements?.start?.date.date
            )
          : unconvertedGraphData.findIndex(
              (el) =>
                t("date_val", {
                  val: new Date(el.date),
                }) === consumptionsRangeElements?.start?.date.date
            ),
        start: aloccatorValue
          ? convertedGraphData.findIndex(
              (el) =>
                t("date_val", {
                  val: new Date(el.date),
                }) === consumptionsRangeElements?.end?.date.date
            )
          : unconvertedGraphData.findIndex(
              (el) =>
                t("date_val", {
                  val: new Date(el.date),
                }) === consumptionsRangeElements?.end?.date.date
            ),
      });
    }
    if (consumptionsRangeElements && type == "07" && data?.length > 0) {
      setRange({
        end: data?.findIndex(
          (el) => el.name === consumptionsRangeElements?.start?.date.date
        ),
        start: data?.findIndex(
          (el) => el.name === consumptionsRangeElements?.end?.date.date
        ),
      });
    }
  }, [consumptionsRangeElements, aloccatorValue]);
  useEffect(() => {
    if (!consumptionsRangeElements && type == "13" && resultArray?.length > 0) {
      setRange({
        start: resultArray?.length > 10 ? resultArray?.length - 10 : 0,
        end: resultArray?.length - 1,
      });
    }
    if (
      !consumptionsRangeElements &&
      type == "08" &&
      allocatorConsum?.length > 0
    ) {
      setRange({
        start: allocatorConsum?.length > 10 ? allocatorConsum?.length - 10 : 0,
        end: allocatorConsum?.length - 1,
      });
    }
    if (!consumptionsRangeElements && type == "07" && data?.length > 0) {
      setRange({
        start: data?.length > 10 ? data?.length - 10 : 0,
        end: data?.length - 1,
      });
    }
  }, [meterData, type]);
  useEffect(() => {
    console.log("grafdata", data);
  }, [data]);

  return (
    <>
      {heatMeter && type == "13" && (
        <>
          <ColorMeterConsumGraph data={resultArray} />
        </>
      )}
      {type == "08" && (
        <Box display={"flex"}>
          <BarChart
            width={width}
            height={530}
            margin={{
              left: 0,
              top: 10,
              right: 30,
            }}
            data={
              aloccatorValue
                ? convertedGraphData?.reverse()
                : unconvertedGraphData?.reverse()
            }
          >
            <PieTooltip
              content={({ active, payload, label }) => {
                const clen = payload;

                if (payload?.[0]?.payload?.type == 1) {
                  return (
                    <GraficCard
                      value={`${
                        aloccatorValue
                          ? t("converted_value")
                          : t("unconverted_value")
                      }: ${payload?.[0]?.payload?.value}`}
                      icon={
                        <CalculateIcon
                          fontSize="large"
                          sx={{ fill: "green" }}
                        />
                      }
                      date={`Date:  ${t("date_val", {
                        val: new Date(payload?.[0]?.payload?.date),
                      })}`}
                    />
                  );
                } else {
                  return (
                    <GraficCard
                      value={`${
                        aloccatorValue
                          ? t("converted_value")
                          : t("unconverted_value")
                      }: ${payload?.[0]?.payload?.value}`}
                      icon={
                        <OnlinePredictionIcon
                          fontSize="large"
                          sx={{ fill: "#8884d8" }}
                        />
                      }
                      date={`Date:  ${t("date_val", {
                        val: new Date(payload?.[0]?.payload?.date),
                      })}`}
                    />
                  );
                }
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              label={{ value: t("date"), position: "insideBottom", offset: 5 }}
              dataKey="date"
              angle={45}
              interval="preserveStartEnd"
              height={90}
              stroke={theme.palette.group_and_district.main}
              tickMargin={30}
              tick={{ fontSize: 10 }}
              tickFormatter={(value) => {
                return t("date_val", { val: new Date(value) });
              }}
            />
            <YAxis
              type="number"
              label={{
                position: "insideLeft",
              }}
              tick={{ fontSize: 10 }}
              style={{ fontSize: "10px" }}
              stroke={theme.palette.group_and_district.main}
            />

            <Bar dataKey="value" fill="#8884d8" minPointSize={1} />
          </BarChart>
          <FormGroup>
            <FormControlLabel
              value={aloccatorValue}
              onClick={allocatorValueToggle}
              control={<Switch defaultChecked />}
              label={
                aloccatorValue ? t("converted_value") : t("unconverted_value")
              }
            />
          </FormGroup>
        </Box>
      )}
      {type == "07" && (
        <BarChart
          width={width}
          height={530}
          margin={{
            left: 0,
            top: 10,
            right: 30,
          }}
          data={data.reverse()}
        >
          <PieTooltip
            content={({ active, payload, label }) => {
              const clen = payload;
              if (payload?.[0]?.payload?.type == 1) {
                return (
                  <GraficCard
                    value={`Value: ${t("number", {
                      val: payload?.[0]?.payload?.value,
                      maximumFractionDigits: 3,
                    })}`}
                    icon={
                      <CalculateIcon fontSize="large" sx={{ fill: "green" }} />
                    }
                    date={`Date: ${payload?.[0]?.payload?.name}`}
                  />
                );
              } else {
                return (
                  <GraficCard
                    value={`Value: ${t("number", {
                      val: payload?.[0]?.payload?.value,
                      maximumFractionDigits: 3,
                    })}`}
                    icon={
                      <OnlinePredictionIcon
                        fontSize="large"
                        sx={{ fill: "#8884d8" }}
                      />
                    }
                    date={`Date: ${payload?.[0]?.payload?.name}`}
                  />
                );
              }
            }}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            angle={45}
            interval="preserveStartEnd"
            height={90}
            tick={{ fontSize: 10 }}
            tickMargin={30}
          />
          <YAxis
            type="number"
            tick={{ fontSize: 10 }}
            label={{
              value: t("m³"),
              angle: -45,
              position: "insideLeft",
            }}
          />
          <Brush
            dataKey="name"
            height={30}
            travellerWidth={10}
            startIndex={range?.start}
            endIndex={range?.end}
          />
          <Bar dataKey="value" fill="#8884d8" minPointSize={5} />
        </BarChart>
      )}
    </>
  );
};
export default MeterConsumptionsGrafic;
