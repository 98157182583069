import React, { useEffect, useState } from "react";
import { useMeasure } from "react-use";
import { Box } from "@material-ui/core";
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryZoomContainer,
  VictoryGroup,
  VictoryTooltip,
  VictoryTheme,
  VictoryScatter,
  createContainer,
} from "victory";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const scaleFrigories = (value) => value * 100;
const ZoomVoronoiContainer = createContainer("zoom", "voronoi");

export default function ColorMeterReadingGraph() {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const graphData = useSelector((state) => state.meterReadingSlice?.data);

  const data = graphData
    ?.map((item) => {
      let hotValue = item?.heat_reading;
      let coldValue = item?.cold_reading;

      if (typeof item?.heat_reading === "string") {
        hotValue = parseFloat(item?.heat_reading.replace(",", "."));
      }
      if (typeof item?.cold_reading === "string") {
        coldValue = parseFloat(item?.cold_reading.replace(",", "."));
      }

      return {
        x: t("date_val", { val: new Date(item?.oneDay) }),
        calories:
          isNaN(hotValue) || hotValue === null
            ? "Nessuna lettura"
            : hotValue / 1000,
        frigories:
          isNaN(coldValue) || coldValue === null
            ? "Nessuna lettura"
            : coldValue / 1000,
      };
    })
    .filter(
      (item) =>
        item.calories !== "Nessuna lettura" &&
        item.frigories !== "Nessuna lettura" &&
        item.calories !== null &&
        item.frigories !== null
    );

  const getMinMaxValues = (data, key) => {
    const min = Math.min(...data?.map((item) => item[key]));
    const max = Math.max(...data?.map((item) => item[key]));
    return { min, max };
  };

  const { min: minCalories, max: maxCalories } =
    data?.length > 0 && getMinMaxValues(data, "calories");
  const { min: minFrigories, max: maxFrigories } =
    data?.length > 0 && getMinMaxValues(data, "frigories");

  const scaleFactorFrigories =
    maxFrigories === 0 ? 1 : maxCalories / maxFrigories;
  const caloriesData = data?.map((item, i) => ({
    x: item.x,
    y: item.calories,
    calories: item.calories,
    frigories: item.frigories,
    index: i,
  }));

  const frigoriesData = data?.map((item, i) => ({
    x: item.x,
    y: item.frigories * scaleFactorFrigories,
    calories: item.calories,
    frigories: item.frigories,
    index: i,
  }));

  const uniqueFrigories = [...new Set(frigoriesData?.map((item) => item.y))];

  const [ref, { width }] = useMeasure();
  const caloriesDomain = [minCalories - 0.5, maxCalories + 0.5];
  const frigoriesDomain = [
    minFrigories * scaleFactorFrigories - 0.5,
    maxFrigories * scaleFactorFrigories + 0.5,
  ];

  const tickFormatFrigories = (t) => {
    const formattedValue = t / scaleFactorFrigories;

    if (maxFrigories === 0) {
      const roundedValue = Math.round(formattedValue);
      return `${roundedValue}`;
    }

    // Иначе работаем как раньше
    const isInteger = Number.isInteger(formattedValue);

    if (formattedValue > 1 && isInteger) {
      const rounded = Math.round(formattedValue);

      if (uniqueFrigories.includes(rounded)) {
        let newValue = rounded;
        let counter = 1;

        while (uniqueFrigories.includes(newValue)) {
          newValue = (rounded + counter * 0.01).toFixed(2);
          counter++;
        }

        uniqueFrigories.push(newValue);
        return newValue;
      }

      uniqueFrigories.push(rounded);
      return `${rounded}`;
    } else {
      const roundedValue = formattedValue.toFixed(2);

      if (uniqueFrigories.includes(roundedValue)) {
        let newValue = parseFloat(roundedValue);
        let counter = 1;

        while (uniqueFrigories.includes(newValue)) {
          newValue = (formattedValue + counter * 0.001).toFixed(3);
          counter++;
        }

        uniqueFrigories.push(newValue);
        return newValue;
      }

      uniqueFrigories.push(roundedValue);
      return roundedValue;
    }
  };

  const tickFormatCalories = (t) => `${Math.round(t)}`;
  useEffect(() => {
    console.log("mappedData", data);
  }, [data]);
  const CustomTooltip = ({ x, y, datum, index }) => {
    console.log("ddaaaatum", datum);
    const tooltipWidth = 175;
    const tooltipHeight = 100;
    const padding = 10;
    const containerWidth = width - padding;
    const containerHeight = 550 - padding;

    let startX = x;
    let startY = y;

    if (x - tooltipWidth / 2 < padding) {
      startX = padding + tooltipWidth / 2;
    } else if (x + tooltipWidth / 2 > containerWidth) {
      startX = containerWidth - tooltipWidth / 2;
    }
    if (y + tooltipHeight > containerHeight) {
      startY = y - tooltipHeight - padding;
    } else if (y - tooltipHeight < padding) {
      startY = padding;
    }

    const truncateText = (text, maxChars) => {
      return text.length > maxChars ? text.slice(0, maxChars) + "..." : text;
    };

    setHoveredIndex(index);

    return (
      <g>
        <rect
          x={startX - tooltipWidth / 2}
          y={startY}
          width={tooltipWidth}
          height={tooltipHeight}
          fill="rgba(230, 230, 230, 0.9)"
          stroke="rgba(0, 0, 0, 0.1)"
          strokeWidth={1.5}
          rx={10}
          ry={10}
          style={{
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "all 0.2s ease",
          }}
        />
        <text
          x={startX - tooltipWidth / 2 + padding} // Внутренний отступ слева
          y={startY + 35}
          textAnchor="start"
          style={{
            fontSize: "16px",
            fontWeight: "500",
            fill: "#333",
            fontFamily: "'Inter', sans-serif",
          }}
        >
          {truncateText(`${t("calories")}: ${datum.calories} kWh`, 20)}
        </text>
        <text
          x={startX - tooltipWidth / 2 + padding}
          y={startY + 55}
          textAnchor="start"
          style={{
            fontSize: "16px",
            fontWeight: "500",
            fill: "#333",
            fontFamily: "'Inter', sans-serif",
          }}
        >
          {truncateText(`${t("frigories")}: ${datum.frigories} kWh`, 20)}
        </text>
        <text
          x={startX - tooltipWidth / 2 + padding}
          y={startY + 75}
          textAnchor="start"
          style={{
            fontSize: "13px",
            fill: "#666",
            fontFamily: "'Inter', sans-serif",
            fontWeight: "400",
          }}
        >
          {truncateText(`${t("date")}: ${datum?.x}`, 20)}
        </text>
      </g>
    );
  };
  useEffect(() => {
    console.log("uniqueFrigories", tickFormatFrigories);
  }, [tickFormatFrigories]);

  return (
    <>
      {(caloriesData?.length > 0 || frigoriesData?.length > 0) && (
        <Box ref={ref}>
          <VictoryChart
            width={width}
            height={550}
            domainPadding={{ x: [20, 20], y: [20, 20] }}
            theme={VictoryTheme.material}
            padding={{ top: 20, bottom: 100, left: 80, right: 80 }}
            containerComponent={
              <ZoomVoronoiContainer
                zoomDimension="x"
                voronoiDimension="x"
                mouseFollowTooltips
                allowZoom={data?.length > 5}
              />
            }
          >
            {/* Ось X */}
            <VictoryAxis
              tickFormat={data?.map((item) => item.x)}
              tickCount={20}
              style={{
                tickLabels: { angle: -45, fontSize: 10, padding: 15 },
              }}
            />

            {/* Ось Y для calories (левая) */}
            <VictoryAxis
              dependentAxis
              orientation="left"
              style={{
                axis: { stroke: "#ff3b30" },
                tickLabels: { fontSize: 10, fill: "#ff3b30" },
              }}
              domain={caloriesDomain}
              tickFormat={tickFormatCalories}
            />

            {/* Ось Y для frigories (правая) */}
            <VictoryAxis
              dependentAxis
              orientation="right"
              style={{
                axis: { stroke: "#33ccdb" },
                tickLabels: { fontSize: 10, fill: "#33ccdb" },
              }}
              tickFormat={tickFormatFrigories}
            />

            <VictoryGroup>
              {/* Линия для calories */}
              <VictoryLine
                data={caloriesData}
                style={{
                  data: { stroke: "#ff3b30", strokeWidth: 2.5 },
                }}
                domain={{
                  y: [
                    minCalories - 0.5,
                    Math.max(maxCalories, Math.ceil(maxCalories / 100) * 100),
                  ],
                }} // Используем maxCalories с округлением
                labels={({ datum }) =>
                  datum.index === hoveredIndex ? "" : null
                }
              />

              <VictoryScatter
                data={caloriesData}
                size={({ index }) => (index === hoveredIndex ? 6 : 3)}
                style={{
                  data: {
                    fill: "#ff3b30",
                    strokeWidth: 1,
                    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))",
                  },
                }}
              />
              <VictoryScatter
                data={frigoriesData}
                size={({ index }) => (index === hoveredIndex ? 6 : 3)}
                style={{
                  data: {
                    fill: "#33ccdb",
                    strokeWidth: 1,
                    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))",
                  },
                }}
                labels={({ datum }) => {}}
                labelComponent={
                  <VictoryTooltip flyoutComponent={<CustomTooltip />} />
                }
              />
              <VictoryLine
                data={frigoriesData}
                style={{
                  data: { stroke: "#33ccdb", strokeWidth: 2.5 },
                }}
                domain={{ y: frigoriesDomain }}
              />
            </VictoryGroup>
          </VictoryChart>
        </Box>
      )}
    </>
  );
}
