import { useState, useEffect } from "react";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useMediaQuery } from "@mui/material";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import Tooltip from "@mui/material/Tooltip";
import { prepareParams } from "../../utils";
import { format } from "date-fns";
import { store } from "../../redux/store";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { setConsumRange } from "../../redux/slices/meterSlice";
import { v4 as uuidv4 } from "uuid";
import { Chip } from "@material-ui/core";
import { useRef } from "react";
import theme from "../../theme";
import SearchIcon from "@mui/icons-material/Search";
import BackspaceIcon from "@mui/icons-material/Backspace";
import CalculateIcon from "@mui/icons-material/Calculate";
import { useNavigate } from "react-router-dom";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import FormAccordion from "./formComponents/Accordion";
import DatePicker from "./formComponents/DatePicker";
import MeterDatePicker from "./formComponents/MeterDatePicker";
import IwdDataGrid from "../../pages/IwdDataGrid";
import ReadignConsumptionsGraph from "./ReadignConsumptionsGraph";
import { useTranslation } from "react-i18next";
import MeterConsumptionsGrafic from "./MeterConsumptionsGrafic";

import { Button, Typography } from "@mui/material";
const ReadignConsumptionsTab = ({
  meter,
  apartamentMeterId,
  enteredFromCondominium,
  enteredFromParent,
  impulsiveMeterId,
}) => {
  const heatMeterTypes = ["CALORIE / FRIGORIE", "Calorie", "Frigorie"];
  const [getMeterReadingGraph, { isLoading }, lastPromiseInfo] =
    store.useLazyGetMeterReadingGraphQuery();
  const [firstRender, setFirstRender] = useState(true);
  const [contentWidth, setContentWidth] = useState({
    graphic: null,
    table: null,
  });
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [visibleRows, setVisibleRows] = useState(null);
  const [getDeviceMessages, { isLoading: isLoadingTable }] =
    store.useLazyGetDeviceMessagesQuery();
  const dispatch = useDispatch();
  const [getConsumptions] = store.useLazyGetConsumptionsQuery();
  const consumptions = useSelector((state) => state?.meterSlice?.consumptions);
  const heatUnit = useSelector((state) => state?.meterSlice?.heatUnit);
  const coldUnit = useSelector((state) => state?.meterSlice?.coldUnit);
  const consumptionsCount = useSelector(
    (state) => state?.meterSlice?.consumptionsCount
  );
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const readingCount = useSelector((state) => state.deviceMessageSlice.meta);
  const headerAppBarOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const [mixedData, setMixedData] = useState([]);
  const readingGraphData = useSelector((state) => state.meterReadingSlice.data);
  const readingMessagesData = useSelector(
    (state) => state.deviceMessageSlice.data
  );
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const fromDate = new Date(now);
  fromDate.setDate(fromDate.getDate() - 7);
  fromDate.setHours(0, 0, 0, 0);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  const [refreshParams, setRefreshParams] = useState(false);
  const { t } = useTranslation();
  const [consumPage, setConsumPage] = useState(1);
  const [readingPage, setReadingPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [perConsumPage, setPerConsumPage] = useState(180);
  useEffect(() => {
    if (consumptions?.length > 0 && readingGraphData?.length > 0) {
      var a = consumptions?.map((item) => ({
        name: new Date(item?.date).toLocaleDateString(),
        consumptionsValue: item?.value,
        readingValue: null,
        type: item.type,
      }));

      readingGraphData?.map((item) => {
        const name = new Date(item?.oneDay).toLocaleDateString();
        const element = a.find((ele) => ele.name == name);
        if (element) {
          element["readingValue"] = item.reading;
        } else {
        }
      });

      setMixedData(a);
    }
  }, [consumptions?.length, readingGraphData?.length]);
  useEffect(() => {
    getMeterReadingGraph({
      digitalTwinId: meter.attributes.digital_twin_id,
      deviceId: impulsiveMeterId ?? meter.id,
      from: searchParams.get("from") ?? newFrom.toISOString(),
      to: searchParams.get("to") ?? newTo.toISOString(),
    });

    const messagesToDate = new Date(
      Date.UTC(
        dates.to.getFullYear(),
        dates.to.getMonth(),
        dates.to.getDate() + 1
      )
    );
    const messageDateToParams = new Date(searchParams.get("to"));
    messageDateToParams.setDate(messageDateToParams.getDate() + 1);

    getDeviceMessages({
      digitalTwinId: meter.attributes.digital_twin_id,
      deviceId: impulsiveMeterId ?? meter.id,
      from: searchParams.get("from") ?? newFrom.toISOString(),
      to: searchParams.get("to")
        ? messageDateToParams.toISOString()
        : messagesToDate.toISOString(),
      page: readingPage,
      perPage: perPage,
    });
    /*  } */
    if (!consumptions) {
      getConsumptions({ ...paramsList });
    }
    setFirstRender(false);
  }, []);
  useEffect(() => {
    if (!firstRender) {
      const messagesDateTo = new Date(formData.insertion_date.to);
      messagesDateTo.setDate(messagesDateTo.getDate() + 1);
      getMeterReadingGraph({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? meter.id,
        from: formData?.insertion_date?.from,
        to: formData?.insertion_date?.to,
      });
      getDeviceMessages({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? meter.id,
        from: formData?.insertion_date?.from,
        to: messagesDateTo.toISOString(),
        page: readingPage,
        perPage: perPage,
      });
    }
  }, [readingPage, meter?.id, impulsiveMeterId]);

  const dates = {
    from: new Date(),
    to: new Date(),
  };

  const newFrom = new Date(
    Date.UTC(
      dates.from.getFullYear(),
      dates.from.getMonth(),
      dates.from.getDate() - 7
    )
  );
  const newTo = new Date(
    Date.UTC(dates.to.getFullYear(), dates.to.getMonth(), dates.to.getDate())
  );
  const paramsList = {
    page: consumPage,
    per_page: perConsumPage,
    device_id: impulsiveMeterId ?? meter.id,
    from: searchParams.get("from") ?? newFrom.toISOString(),
    to: searchParams.get("to") ?? newTo.toISOString(),
  };
  const schema = {
    type: "object",
    properties: {
      insertion_date: {
        id: 3,
        title: t("inserted_at"),
        type: "object",
        properties: {
          from: {
            type: "string",
            default: paramsList.from ?? fromDate.toISOString(),
          },
          to: {
            type: "string",
            default: paramsList.to ?? now.toISOString(),
          },
        },
      },
    },
  };
  const fields = {
    datepicker: MeterDatePicker,
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },

    insertion_date: {
      "ui:field": "datepicker",
    },
  };
  const onSubmitClick = (obj) => {
    obj["page"] = 1;
    obj["per_page"] = perPage;

    const params = prepareParams(obj);
    if (!enteredFromCondominium && !enteredFromParent) {
      navigate({
        pathname: `/meters/${meter.id}`,
        search: params.toString(),
      });
    }
    setConsumPage(1);
    setReadingPage(1);
    setRefreshParams(true);
    dispatch(setConsumRange(null));
  };
  useEffect(() => {
    if (refreshParams) {
      if (!enteredFromCondominium) {
        getConsumptions({ ...paramsList });
      } else {
        getConsumptions({
          ...paramsList,
          from: formData?.insertion_date?.from,
          to: formData?.insertion_date?.to,
        });
      }

      const messagesDateTo = new Date(formData.insertion_date.to);
      messagesDateTo.setDate(messagesDateTo.getDate() + 1);
      getMeterReadingGraph({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? meter.id,
        from: formData?.insertion_date?.from ?? newFrom.toISOString(),
        to: formData?.insertion_date?.to ?? newTo.toISOString(),
      });
      getDeviceMessages({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? meter.id,
        from: formData?.insertion_date?.from,
        to: messagesDateTo.toISOString(),
        page: readingPage,
        perPage: perPage,
      });
    }
    setRefreshParams(false);
  }, [refreshParams]);
  const onClearClick = () => {
    setFormData((prev) => ({
      ...prev,
      insertion_date: {
        from: null,
        to: null,
      },
    }));

    const params = prepareParams({});
    if (!enteredFromCondominium && !enteredFromParent) {
      navigate({
        pathname: `/meters/${meter.id}`,
        search: params.toString(),
      });
    }
  };
  const customValidate = (formData, errors) => {
    if (formData.insertion_date.from && formData.insertion_date.to) {
      if (
        formData.insertion_date.from !== "" &&
        formData.insertion_date.to !== "" &&
        formData.insertion_date.from >= formData.insertion_date.to
      ) {
        errors.insertion_date.to.addError(t("date_error"));
      }
    } else {
      if (!formData.insertion_date.from && formData.insertion_date.to) {
        errors.insertion_date.from.addError(t("missing_startDate"));
      }
    }

    return errors;
  };
  const heatConsumColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "left",
    },

    {
      field: "date",
      headerName: t("date"),
      type: "number",
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "type",
      headerName: t("type"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value == 1) {
          return (
            <Tooltip title={t("calculated")}>
              <CalculateIcon sx={{ fill: "green" }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={t("simulated")}>
              <OnlinePredictionIcon sx={{ fill: "#8884d8" }} />
            </Tooltip>
          );
        }
      },
    },
    {
      field: "calories",
      headerName: t("calories_consum"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    {
      field: "frigories",
      headerName: t("frigories_consum"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
  ];
  const heatConsumptions =
    consumptions &&
    consumptions?.reduce((acc, curr) => {
      const existingEntryIndex = acc.findIndex(
        (entry) => entry.date === curr.date
      );
      if (existingEntryIndex !== -1) {
        if (curr.consumption_type === "heat") {
          acc[existingEntryIndex].calories = curr.value;
        } else if (curr.consumption_type === "cold") {
          acc[existingEntryIndex].frigories = curr.value;
        }
      } else {
        const newObj = {
          ...curr,
          calories: curr.consumption_type === "heat" ? curr.value : undefined,
          frigories: curr.consumption_type === "cold" ? curr.value : undefined,
        };
        acc.push(newObj);
      }
      return acc;
    }, []);
  const allocatorConsum =
    consumptions &&
    consumptions?.reduce((acc, curr) => {
      const existingEntryIndex = acc.findIndex(
        (entry) => entry.date === curr.date
      );
      if (existingEntryIndex !== -1) {
        if (curr.consumption_type === "unconverted_value") {
          acc[existingEntryIndex].unconverted_value = curr.value;
        } else if (curr.consumption_type === "converted_value") {
          acc[existingEntryIndex].converted_value = curr.value;
        }
      } else {
        const newObj = {
          ...curr,
          unconverted_value:
            curr.consumption_type === "unconverted_value"
              ? curr.value
              : undefined,
          converted_value:
            curr.consumption_type === "converted_value"
              ? curr.value
              : undefined,
        };
        acc.push(newObj);
      }
      return acc;
    }, []);
  const allocatorColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "left",
    },

    {
      field: "date",
      headerName: t("date"),
      type: "number",

      flex: 2,
      headerAlign: "left",
    },

    {
      field: "type",
      headerName: t("type"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value == 1) {
          return (
            <Tooltip title={t("simulated")}>
              <CalculateIcon sx={{ fill: "green" }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={t("calculated")}>
              <OnlinePredictionIcon sx={{ fill: "#8884d8" }} />
            </Tooltip>
          );
        }
      },
    },
    {
      field: "converted_value",
      headerName: t("converted_value"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    {
      field: "unconverted_value",
      headerName: t("unconverted_value"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
  ];
  const allocatorRows = allocatorConsum?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      date: t("date_val", { val: new Date(item?.date) }),
      type: item.type,
      converted_value:
        item?.converted_value !== null
          ? item.converted_value?.toFixed(0)
          : "--",
      unconverted_value:
        item?.unconverted_value !== null
          ? item.unconverted_value?.toFixed(0)
          : "--",
    };
  });
  const heatConsumRows = heatConsumptions?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      date: t("date_val", { val: new Date(item?.date) }),
      type: item.type,
      calories:
        item?.calories !== null
          ? t("number", {
              val: item.calories,
            })
          : "--",
      frigories:
        item?.frigories !== null
          ? t("number", {
              val: item.frigories,
            })
          : "--",
    };
  });

  const handlePageChange = () => {
    const startIndex = (consumPage - 1) * 10;
    const endIndex = startIndex + 10;
    setVisibleRows(heatConsumRows?.slice(startIndex, endIndex));
  };
  useEffect(() => {
    console.log("cosum from readconsum tab", consumptions);
  }, [consumptions]);
  useEffect(() => {
    if (!firstRender) {
      handlePageChange();
    }
  }, [consumPage]);
  const consumColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "left",
    },

    {
      field: "date",
      headerName: t("date"),
      type: "number",
      sortable: false,

      flex: 1,
      headerAlign: "left",
    },

    {
      field: "type",
      headerName: t("type"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => {
        if (params.value == 1) {
          return (
            <Tooltip title={t("simulated")}>
              <OnlinePredictionIcon sx={{ fill: "#8884d8" }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={t("calculated")}>
              <CalculateIcon sx={{ fill: "green" }} />
            </Tooltip>
          );
        }
      },
    },
    {
      field: "value",
      headerName: t("value"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
  ];
  const consumRows = consumptions?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      date: t("date_val", { val: new Date(item?.date) }),

      type: item.type,
      value: t("number", {
        val: item.value,
      }),
    };
  });

  const redingsRows = readingMessagesData?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      messageDate: t("dt_n", {
        val: new Date(item?.messageDate),
      }),
      strValue: `${
        t("number", {
          val: item.value,
        }) ?? "-"
      }`,
      unit: item?.unit == "m^3" ? "m³" : item?.unit,
      alarms: item?.alarms,
    };
  });

  const readColumns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 2,
    },

    {
      field: "strValue",
      headerName: t("volume"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,

      editable: false,
      flex: 1,
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.row.alarms === undefined) {
          return <>--</>;
        } else {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", paddingRight: 2 }}>
              {params.row.alarms?.map((item) => (
                <Chip
                  key={item}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    margin: "2px",
                  }}
                  label={item}
                />
              ))}
            </Box>
          );
        }
      },
    },
  ];
  const heatReadColumns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,

      editable: false,
      flex: 2,
      renderCell: (params) => {
        return <>{t("dt_n", { val: new Date(params.row.messageDate) })}</>;
      },
    },
    {
      field: "rawValue",
      headerName: t("display_value"),

      editable: false,
      flex: 1,
    },

    {
      field: "k",
      headerName: t("k"),
      editable: false,

      editable: false,
      flex: 1,
    },

    {
      field: "value",
      headerName: t("value"),
      editable: false,

      editable: false,
      flex: 1,
    },

    {
      field: "alarms",
      headerName: t("alarms"),
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => {
        if (params.row.alarms === undefined) {
          return <>--</>;
        } else {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {params.row.alarms?.map((item) => (
                <Chip
                  key={item}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    margin: "2px",
                  }}
                  label={item}
                />
              ))}
            </Box>
          );
        }
      },
    },
  ];
  useEffect(() => {}, readingMessagesData);
  const heatReadingRows = readingMessagesData?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      messageDate: item?.messageDate,
      value: item.value ? item.value?.toFixed(0) : "--",
      rawValue: item?.last_unconverted_reading
        ? item?.last_unconverted_reading.value?.toFixed(0)
        : "--",
      /*    unit: item?.unit, */
      k: item?.k
        ? `${t("number", {
            val: item?.k,
            maximumFractionDigits: 3,
          })}`
        : "--",
      alarms: item.alarms,
    };
  });
  const heatMeterColumns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,

      editable: false,
      flex: 2,
      renderCell: (params) => {
        return <>{t("dt_n", { val: new Date(params.row.messageDate) })}</>;
      },
    },
    {
      field: "calorie",
      headerName: t("Calorie"),

      editable: false,
      flex: 1,
    },
    {
      field: "frigorie",
      headerName: t("Frigorie"),
      editable: false,

      editable: false,
      flex: 1,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,

      editable: false,
      flex: 1,
    },

    {
      field: "alarms",
      headerName: t("alarms"),
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => {
        if (params.row.alarms === undefined) {
          return <>--</>;
        } else {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {params.row.alarms?.map((item) => (
                <Chip
                  key={item}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    margin: "2px",
                  }}
                  label={item}
                />
              ))}
            </Box>
          );
        }
      },
    },
  ];
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return 1330;
    }
    if (is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return 1272;
    }
    if (is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return 1714;
    }
    if (is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return 1426;
    }
    if (!is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return 1503;
    }
    if (!is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return 1272;
    }
    if (!is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return 1720;
    }
    if (!is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return 1445;
    }
  };

  const styleSelector = (params) => {
    const datastr = params.row.readTime;
    const date = new Date();

    if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled`;
    }
    if (params?.row?.strValue == "--") {
      return `super-app-theme--Rejected`;
    }
  };

  const heatMeterRows = readingMessagesData?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      messageDate: item?.attributes?.message_date,
      calorie: item?.attributes?.values?.values?.value?.heat_last_reading?.value
        ? `${
            heatUnit === "Wh"
              ? `${t("number", {
                  val:
                    item?.attributes?.values?.values?.value?.heat_last_reading
                      ?.value / 1000,
                })}`
              : `${t("number", {
                  val: item?.attributes?.values?.values?.value
                    ?.heat_last_reading?.value,
                })}`
          } `
        : "--",
      frigorie: item?.attributes?.values?.values?.value?.cold_last_reading
        ?.value
        ? `${
            coldUnit === "Wh"
              ? `${t("number", {
                  val:
                    item?.attributes?.values?.values?.value?.cold_last_reading
                      ?.value / 1000,
                })}`
              : `${t("number", {
                  val: item?.attributes?.values?.values?.value
                    ?.cold_last_reading?.value,
                })}`
          } `
        : "--",
      unit:
        coldUnit === "Wh"
          ? "kWh"
          : item?.attributes?.values?.values?.value?.cold_last_reading?.unit,
      alarms: item.alarms,
      alarms: item.alarms,
    };
  });

  useEffect(() => {
    if (visibleRows) {
      dispatch(
        setConsumRange({
          start: visibleRows[0],
          end: visibleRows[visibleRows.length - 1],
        })
      );
    }
  }, [visibleRows]);
  const getRowHeight = (params) => {
    const defaultHeight = 41.2;
    const model = params?.model || {};
    const alarms = model?.alarms || [];

    const extraHeight = Math.floor(alarms.length / 2) * 62;
    const rowHeight = defaultHeight + extraHeight;

    return rowHeight;
  };

  const updateDimensions = () => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    // Вычисляем размеры при монтировании
    updateDimensions();

    // Обновляем размеры при изменении окна
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: "100%", // Контейнер занимает всю ширину
        height: "100vh", // Высота на весь экран
        display: "flex",
        flexDirection: "column", // Вертикальная организация
      }}
    >
      <FormAccordion width="100%">
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          customValidate={customValidate}
          showErrorList={false}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          validator={validator}
          uiSchema={uiSchema}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            flexWrap="wrap"
            sx={{ gap: 2 }}
          >
            <Tooltip title={t("clear")}>
              <Button
                onClick={() => onClearClick(formData)}
                sx={{ mr: 2 }}
                variant={window.BUTTON_TYPE}
              >
                <BackspaceIcon sx={{ fill: "#fff" }} />
              </Button>
            </Tooltip>
            <Tooltip title={t("search")}>
              <Button
                variant={window.BUTTON_TYPE}
                onClick={() => onSubmitClick(formData)}
                disabled={
                  formData?.insertion_date?.from == null ||
                  formData?.insertion_date?.to == null
                }
              >
                <SearchIcon sx={{ fill: "#fff" }} />
              </Button>
            </Tooltip>
          </Box>
        </Form>
      </FormAccordion>

      {mixedData?.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <FormAccordion title={t("graphic")} width={dimensions.width}>
            <MeterConsumptionsGrafic
              width={dimensions.width}
              meterData={consumptions}
              type={meter?.attributes?.fields?.type}
              allocatorConsum={allocatorConsum}
              heatMeter={heatMeterTypes.includes(
                meter?.attributes?.fields?.meter_type
              )}
            />
          </FormAccordion>
        </Box>
      )}

      {consumptions && readingMessagesData && (
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: 4,
            width: "100%",
            gap: { xs: 2, md: 4 },
          }}
        >
          <Box height={522} sx={{ width: { xs: "100%", md: "50%" }, mb: 10 }}>
            <Typography variant="h5" align="center" sx={{ mb: 1 }}>
              {t("consumptions")}
            </Typography>
            {meter?.attributes?.fields?.type == 13 && (
              <IwdDataGrid
                rows={heatConsumRows}
                columns={heatConsumColumns}
                perPage={10}
                setPage={setConsumPage}
                page={consumPage}
                count={heatConsumptions.length}
                sort={false}
                height={522}
                paginationMode="client"
                tableName="meter_heat_consumptions_reading"
              />
            )}
            {meter?.attributes?.fields?.type == "08" && (
              <IwdDataGrid
                rows={allocatorRows}
                columns={allocatorColumns}
                perPage={10}
                setPage={setConsumPage}
                page={consumPage}
                count={heatConsumptions.length}
                sort={false}
                height={522}
                paginationMode="client"
                tableName="meter_hca_consumptions_reading"
              />
            )}
            {meter?.attributes?.fields?.type == "07" && (
              <IwdDataGrid
                rows={consumRows}
                columns={consumColumns}
                perPage={10}
                setPage={setConsumPage}
                page={consumPage}
                count={heatConsumptions.length}
                sort={false}
                height={522}
                paginationMode="client"
                tableName="meter_water_consumptions_reading"
              />
            )}
          </Box>

          <Box
            height={522}
            sx={{
              width: { xs: "100%", md: "50%" },
              mt: { xs: 2, md: 0 },
              mb: 10,
            }}
          >
            <Typography variant="h5" align="center" sx={{ mb: 1 }}>
              {t("readings")}
            </Typography>
            {meter?.attributes?.fields?.type == "07" && (
              <IwdDataGrid
                rows={redingsRows}
                columns={readColumns}
                perPage={perPage}
                setPage={setReadingPage}
                page={readingPage}
                count={readingCount?.count}
                sort={false}
                height={522}
                paginationMode="server"
                tableName="meter_water_reading_consumptions"
                getRowHeight={getRowHeight}
              />
            )}
            {meter?.attributes?.fields?.type == "08" && (
              <IwdDataGrid
                rows={heatReadingRows}
                columns={heatReadColumns}
                perPage={perPage}
                setPage={setReadingPage}
                page={readingPage}
                count={readingCount?.count}
                sort={false}
                height={522}
                paginationMode="server"
                tableName="meter_hca_reading_consumptions"
                getRowHeight={getRowHeight}
              />
            )}
            {meter?.attributes?.fields?.type == 13 && (
              <IwdDataGrid
                rows={heatMeterRows}
                columns={heatMeterColumns}
                perPage={perPage}
                setPage={setReadingPage}
                page={readingPage}
                count={readingCount?.count}
                sort={false}
                height={522}
                paginationMode="server"
                tableName="meter_heat_reading_consumptions"
                getRowHeight={getRowHeight}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default ReadignConsumptionsTab;
