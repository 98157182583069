import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";
import { useSelector } from "react-redux";
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryTheme,
  VictoryScatter,
  VictoryArea,
  createContainer,
} from "victory";

const ZoomVoronoiContainer = createContainer("zoom", "voronoi");

const MeterReadingsGraph = ({ meter, unit }) => {
  const { t } = useTranslation();
  const data = useSelector((state) => state.meterReadingSlice?.data);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const calculateDynamicDomain = (data) => {
    if (data.length === 0) return [0, 1]; // Если данных нет, вернём дефолтный диапазон

    if (data.length < 5) {
      const yValues = data.map((item) => item.y);

      const minY = Math.min(...yValues);
      const maxY = Math.max(...yValues);

      if (minY === maxY) {
        const rangeStep = 0.5;
        return [Math.max(minY - rangeStep, 0), minY + rangeStep];
      }

      const rangeDifference = maxY - minY;
      const step = rangeDifference / (data.length - 1 || 1);

      const adjustedMin = Math.max(minY - step, 0);
      const adjustedMax = maxY + step;

      return [adjustedMin, adjustedMax];
    }

    const yValues = data.map((item) => item.y);
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);

    return [Math.max(minY - 0.1, 0), maxY + 0.1];
  };

  const mappedData = data
    ?.map((item, i) => {
      let numericValue = item.reading;
      if (typeof item.reading === "string") {
        numericValue = parseFloat(item.reading.replace(",", "."));
      }
      if (!isNaN(numericValue)) {
        numericValue = parseFloat(numericValue.toFixed(2));
        return {
          x: t("date_val", { val: new Date(item.oneDay) }),
          y: unit === "m3" ? numericValue : numericValue,
          index: i,
        };
      }
      return undefined;
    })
    .filter((item) => item !== undefined);

  /*   const mappedData = [
    {
      x: "15/10/2024",
      y: 0.23,
      index: 164,
    },
    {
      x: "16/10/2024",
      y: 0.48,
      index: 164,
    },
    {
      x: "18/10/2024",
      y: 0.48,
      index: 164,
    },
    {
      x: "19/10/2024",
      y: 0.58,
      index: 164,
    },
  ]; */
  const CustomTooltip = ({ x, y, datum, index }) => {
    const tooltipWidth = 150;
    const tooltipHeight = 100;
    const padding = 10;
    const containerWidth = width - padding;
    const containerHeight = 550 - padding;

    let startX = x;
    let startY = y;

    if (x - tooltipWidth / 2 < padding) {
      startX = padding + tooltipWidth / 2;
    } else if (x + tooltipWidth / 2 > containerWidth) {
      startX = containerWidth - tooltipWidth / 2;
    }
    if (y + tooltipHeight > containerHeight) {
      startY = y - tooltipHeight - padding;
    } else if (y - tooltipHeight < padding) {
      startY = padding;
    }

    const truncateText = (text, maxChars) => {
      return text.length > maxChars ? text.slice(0, maxChars) + "..." : text;
    };

    setHoveredIndex(index);

    return (
      <g>
        <rect
          x={startX - tooltipWidth / 2}
          y={startY}
          width={tooltipWidth}
          height={tooltipHeight}
          fill="rgba(230, 230, 230, 0.9)"
          stroke="rgba(0, 0, 0, 0.1)"
          strokeWidth={1.5}
          rx={10}
          ry={10}
          style={{
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "all 0.2s ease",
          }}
        />

        <text
          x={startX - tooltipWidth / 2 + padding}
          y={startY + 45}
          textAnchor="start"
          style={{
            fontSize: "16px",
            fontWeight: "500",
            fill: "#333",
            fontFamily: "'Inter', sans-serif",
          }}
        >
          {`${t("value")}: ${datum.y} ${
            meter?.attributes?.fields?.type === "07" ? "m³" : ""
          }`}
        </text>
        <text
          x={startX - tooltipWidth / 2 + padding}
          y={startY + 65}
          textAnchor="start"
          style={{
            fontSize: "13px",
            fill: "#666",
            fontFamily: "'Inter', sans-serif",
            fontWeight: "400",
          }}
        >
          {truncateText(`${t("date")}: ${datum?.x}`, 20)}
        </text>
      </g>
    );
  };

  const [ref, { width }] = useMeasure();

  useEffect(() => {
    console.log("mappedData", mappedData);
  }, [mappedData]);

  if (!data) {
    return <Box ref={ref}>Loading</Box>;
  }

  return (
    <Box ref={ref} sx={{ width: "100%" }}>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="chart-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: "#fefff4", stopOpacity: 0.9 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "#d3e2b6", stopOpacity: 0.7 }}
            />
          </linearGradient>
        </defs>
      </svg>

      <VictoryChart
        width={width}
        height={550}
        theme={VictoryTheme.material}
        padding={{ top: 20, bottom: 60, left: 60, right: 20 }}
        domainPadding={{ x: [5, 10], y: [10, 10] }}
        containerComponent={
          <ZoomVoronoiContainer
            zoomDimension="x"
            voronoiDimension="x"
            allowZoom={mappedData?.length > 5}
            mouseFollowTooltips
            onDeactivated={() => {
              setHoveredIndex(null);
            }}
          />
        }
      >
        <VictoryAxis
          tickFormat={(t) => t}
          tickCount={20}
          style={{
            axis: { stroke: "#bcc3cb" },
            tickLabels: { angle: 65, textAnchor: "start", fontSize: 10 },
          }}
        />

        <VictoryAxis
          dependentAxis
          tickFormat={(value) => value?.toFixed(2)}
          style={{
            axis: { stroke: "#bcc3cb" },
            grid: { stroke: "#e0e0e0" },
            ticks: { stroke: "#e0e0e0", size: 5 },
            tickLabels: { fontSize: 10, padding: 5 },
          }}
          domain={calculateDynamicDomain(mappedData)}
        />

        <VictoryScatter
          data={mappedData}
          size={({ index }) => (index === hoveredIndex ? 6 : 3)}
          style={{
            data: {
              fill:
                meter?.attributes?.fields?.type === "07"
                  ? "#33ccdb"
                  : "#fb8e91",
              strokeWidth: 1,
              filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))",
            },
          }}
          labels={({ datum }) => {}}
          labelComponent={
            <VictoryTooltip flyoutComponent={<CustomTooltip />} />
          }
          events={[
            {
              target: "data",
              eventHandlers: {
                onMouseLeave: () => {
                  setHoveredIndex(null);
                  return null;
                },
              },
            },
          ]}
        />

        <VictoryLine
          data={mappedData}
          x="x"
          y="y"
          style={{
            data: {
              stroke:
                meter?.attributes?.fields?.type === "07"
                  ? "#33ccdb"
                  : "#fb8e91",
              strokeWidth: 2.5,
              strokeLinecap: "round",
            },
          }}
        />
      </VictoryChart>
    </Box>
  );
};

export default MeterReadingsGraph;
